exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-earthtones-js": () => import("./../../../src/pages/earthtones.js" /* webpackChunkName: "component---src-pages-earthtones-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-percobaan-js": () => import("./../../../src/pages/percobaan.js" /* webpackChunkName: "component---src-pages-percobaan-js" */),
  "component---src-pages-press-and-news-js": () => import("./../../../src/pages/press-and-news.js" /* webpackChunkName: "component---src-pages-press-and-news-js" */),
  "component---src-pages-tech-and-data-js": () => import("./../../../src/pages/tech-and-data.js" /* webpackChunkName: "component---src-pages-tech-and-data-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

